import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Project from "./Project";
import HomeVideoModal from "../home/HomeVideoModal";

// Misfit Wrapping - block 6
//import bannerMisfit from '../../images/home-misfit-wrapping.jpeg'
import bannerRayban from "../../images/rayban-hero.png";
//import img6 from '../../images/home-misfit-wrapping.png'
import Video1 from "../../media/rayban-vid-hero-720.mp4";

import Porsche from "../../images/pu-img-banner-up.png";
import fromwhere from "../../images/home-from-where-imini.jpeg";

function ProjectRayBan(props) {
  const { projectID, rect, expanded, videoOpen } = props;
  const block = {
    id: "rayban",
    /* banner: (
      <StaticImage
        src={"../../images/rayban-hero.png"}
        style={{
          top: 0,
          left: 0,
          width: "100%",
          maxWidth: "unset",
          height: "100%",
        }}
        loading={"eager"}
        alt={"rayban-banner"}
        className={"project-image project-banner"}
      />
    ), */
    banner: bannerRayban,
    img: bannerRayban,
    bannerSmall: bannerRayban,
    infoheader: "Project Info",
    subheader: "Our shift starts when yours can't.",
    metadata: "Experience the ultimate summer celebration with Ray-Ban Sun-Day, a four-city music festival chasing the sun from Tel Aviv to NYC. Hosted by Public School, the finale event at Brooklyn's William Vale rooftop featured creative activations, emerging artists, and the iconic energy of Ray-Ban.",
    info: [
      "Ray-Ban Sun-Day is a single-day, four-city music festival that celebrates the sun — by literally chasing it over the course of the longest day of the year. The party starts in Tel Aviv, then on to Paris, then London, until finally culminating in New York City",
      "Public School was challenged with creating the finale event — creating a night of music, influencers and hit makers on the William Vale rooftop in Brooklyn, New York.",
      "The high-rise event venue put attendees closer to the guest of honor (the sun!) while they enjoyed a few creative activations worthy of the warm weather, and performances from four brilliant emerging music artists. ",
      "Throughout it all, Ray-Ban shined bright at the center of the story, solidifying its reputation as the indisputable eyewear brand for pushing boundaries and empowering possibilities.",
    ],
    brand: "Ray-Ban",
    project: "Sun-Day",
    tag: "Branding",
    prevTag: "Porsche Unseen SXSW",
    nextTag: "MINI Countryman Launch",
    prevLink: "/project/porsche-unseen",
    prevImage: Porsche,
    nextLink: "/project/from-where-i-mini",
    nextImage: fromwhere,
  };
  const marqueeText = [
    { type: "p", text: "Ray-Ban", id: "rayban" },
    { type: "span", text: "Sun-Day" },
    { type: "p", text: "Ray-Ban" },
    { type: "span", text: "Sun-Day" },
    /* { type: "p", text: "Ray Ban", id: "rayban" },
    { type: "span", text: "Sun-Day" }, */
  ];

  return (
    <Project block={block} title={marqueeText} {...props}>
      <div className={'project-video'}>
          <HomeVideoModal
              key={`homeBlock-${block.id}`}
              block={block}
              video="https://stream.mux.com/MuNdPK62Cy3QRejX9Qa5hkIGH5k6WB4a00kLfpfnVvyg.m3u8"
              prevRect={rect}
              navExpand={videoOpen}
              hideSound={true}
          /> 
      </div>
      <div className="pwrap">
        {/* <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-12"
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}>
            <StaticImage
              src={"../../images/rayban-video.png"}
              alt={"project-video"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div> */}
        {/* <div className="pgrid">
          <div
            className="pgrid-cols-14"
            style={{
              width: "100%",
              aspectRatio: "16/9",
              display: "flex",
            }}>
            <HomeVideoModal
              key={`homeBlock-${block.id}`}
              block={block}
              prevRect={rect}
              navExpand={videoOpen}
              video={Video1}
            />
          </div>
        </div> */}

        <div className="pgrid"
          style={{
            marginTop: "180px",
          }}
        >
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/rayban-img-1.png"}
              alt={"project-img-1"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-4"
            style={{
              width: "100%",
              maxWidth: "386px",
            }}>
            <p className="p-matter">
              Guests were welcomed to the heatwave of activations that gave the
              perfect mix of music and play. Attendees entered the experience
              through a tunnel filled with more than 400 reflective beach balls,
              to the sounds of the Ray-Ban Sun-Day manifesto.
            </p>
            <br />
            <p className="p-matter">
              As guests emerged, they were met with several engaging activations
              — a Sun-Day Tattoo Parlor, where guests received sun-inspired
              golden tattoos, a Sun-Screening Studio, where attendees could
              create their own silk screened hats and t-shirts, and the Sun-Deck
              art installation, where an array of Ray-Ban sunglasses where on
              display and ready to try on.
            </p>
          </div>
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-4"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/rayban-img-2.png"}
              alt={"project-img-2"}
            />
          </div>
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
              marginLeft: "20px",
            }}>
            <StaticImage
              src={"../../images/rayban-img-3.png"}
              alt={"project-img-3"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-14"
            style={{
              width: "100%",
              marginTop: "160px",
            }}>
            <StaticImage
              src={"../../images/rayban-img-4.png"}
              alt={"project-img-4"}
            />
          </div>
        </div>

        <div
          className="pgrid"
          style={{
            marginTop: "160px",
          }}>
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-5"
            style={{
              display: "flex",
              flexFlow: "column",
              width: "100%",
            }}>
            <div
              style={{
                maxWidth: "358px",
                height: "216px",
              }}>
              <p className="p-matter">
                As the sun moved across the sky, the party kept getting hotter
                with musical performances by Baby Tate, Channel Tres, DJ
                Mazurbate and The Knocks. The entire multi-city experience
                celebrated the optimism of the sun, the moment of the solstice
                and the unmatched “Shades On. Volume Up.” energy of Ray-Ban.
              </p>
            </div>
            <div
              style={{
                width: "100%",
              }}>
              <StaticImage
                src={"../../images/rayban-img-6.png"}
                alt={"project-img-6"}
              />
            </div>
          </div>

          <div
            className="pgrid-cols-7"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%",
            }}>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}>
              <StaticImage
                src={"../../images/rayban-img-5a-desktop.png"}
                alt={"project-img-5a"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                width: "364px",
                height: "231px",
              }}>
              <StaticImage
                width={1200}
                height={764}
                src={"../../images/rayban-img-7.jpg"}
                alt={"project-img-7"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                width: "289px",
                marginLeft: "100px",
                marginTop: "50px",
              }}>
              {/* <p className="p-matter">
                ”We are sun seekers. We thrive in the light. The solstice is our
                moment and we want you to join us. As we dance through the
                longest day of the year. The sun will rise and so will the heat.
                Let’s bask in the golden glow and celebrate the energy. Shades
                on. Volume Up.” 
              </p> */}
            </div>
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-2"></div>
          <div
            className="pgrid-cols-10"
            style={{
              marginTop: "160px",
              marginBottom: "160px",
              display: "flex",
              justifyContent: "center",
            }}>
            <p className="project-tagline">
              ”We are sun seekers. We thrive in the light. The solstice is our
              moment and we want you to join us. As we dance through the longest
              day of the year. The sun will rise and so will the heat.{" "}
              <span>
                Let’s bask in the golden glow and celebrate the energy. Shades
                on. Volume Up.
              </span>
              ”
            </p>
          </div>
          <div className="pgrid-cols-2"></div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/rayban-img-8.png"}
              alt={"project-img-8"}
            />
          </div>
          <div
            className="pgrid-cols-8"
            style={{
              display: "flex",
              flexFlow: "column",
              width: "100%",
              gap: "40px",
              marginLeft: "20px",
            }}>
            <div
              style={{
                width: "520px",
              }}>
              <StaticImage
                src={"../../images/rayban-img-9.png"}
                alt={"project-img-9"}
              />
            </div>
            <div
              style={{
                width: "100%",
              }}>
              <StaticImage
                src={"../../images/rayban-img-10.png"}
                alt={"project-img-10"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pwrap-mobile">
        {/* <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
            }}>
            <HomeVideoModal
              key={`homeBlock-${block.id}`}
              block={block}
              prevRect={rect}
              navExpand={videoOpen}
              video={Video1}
            />
          </div>
        </div> */}
        {/* <div className="pgrid">
          <div
            style={{
              width: "100%",
            }}>
            <div
              className={"project-video"}
              style={{
                width: "100%",
              }}>
              <HomeVideoModal
                key={`homeBlock-${block.id}`}
                block={block}
                video="https://stream.mux.com/MuNdPK62Cy3QRejX9Qa5hkIGH5k6WB4a00kLfpfnVvyg.m3u8"
                prevRect={rect}
                navExpand={videoOpen}
                hideSound={true}
              />
            </div>
          </div>
        </div> */}

        {/* <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}>
            <StaticImage
              src={"../../images/rayban-video.png"}
              alt={"project-video"}
            />
          </div>
        </div> */}

        <div
          className="pgrid"
          style={{
            marginTop: "100px",
          }}>
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <p className="p-matter">
              Guests were welcomed to the heatwave of activations that gave the
              perfect mix of music and play. Attendees entered the experience
              through a tunnel filled with more than 400 reflective beach balls,
              to the sounds of the Ray-Ban Sun-Day manifesto.
            </p>
            <br />
            <p className="p-matter">
              As guests emerged, they were met with several engaging activations
              — a Sun-Day Tattoo Parlor, where guests received sun-inspired
              golden tattoos, a Sun-Screening Studio, where attendees could
              create their own silk screened hats and t-shirts, and the Sun-Deck
              art installation, where an array of Ray-Ban sunglasses where on
              display and ready to try on.
            </p>
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
              display: "flex",
            }}>
            <StaticImage
              width={375}
              height={478}
              src={"../../images/rayban-img-1.png"}
              alt={"project-img-1"}
            />
          </div>
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <StaticImage
              width={279}
              height={175}
              src={"../../images/rayban-img-2.png"}
              alt={"project-img-2"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
            }}>
            <StaticImage
              width={375}
              height={478}
              src={"../../images/rayban-img-3.png"}
              alt={"project-img-3"}
            />
          </div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-14"
            style={{
              width: "100%",
            }}>
            <StaticImage
              width={375}
              height={179}
              src={"../../images/rayban-img-4.png"}
              alt={"project-img-4"}
            />
          </div>
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              display: "flex",
              flexFlow: "column",
              width: "100%",
            }}>
            <div>
              <p className="p-matter">
                As the sun moved across the sky, the party kept getting hotter
                with musical performances by Baby Tate, Channel Tres, DJ
                Mazurbate and The Knocks. The entire multi-city experience
                celebrated the optimism of the sun, the moment of the solstice
                and the unmatched “Shades On. Volume Up.” energy of Ray-Ban.
              </p>
            </div>
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
            width={277}
            height={352}
              src={"../../images/rayban-img-6.png"}
              alt={"project-img-6"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
            width={279}
            height={175}
              src={"../../images/rayban-img-5a-mobile.png"}
              alt={"project-img-5a"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        {/* <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              display: "flex",
              flexFlow: "column",
              width: "100%",
            }}>
            <div>
              <p className="p-matter">
                ”We are sun seekers. We thrive in the light. The solstice is our
                moment and we want you to join us. As we dance through the
                longest day of the year. The sun will rise and so will the heat.
                Let’s bask in the golden glow and celebrate the energy. Shades
                on. Volume Up.” 
              </p>
            </div>
          </div>
          <div className="pgrid-cols-1"></div>
        </div> */}

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
            width={279}
            height={175}
            quality={100}
              src={"../../images/rayban-img-7.jpg"}
              alt={"project-img-5b"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <p className="project-tagline">
              ”We are sun seekers. We thrive in the light. The solstice is our
              moment and we want you to join us. As we dance through the longest
              day of the year. The sun will rise and so will the heat.{" "}
              <span>
                Let’s bask in the golden glow and celebrate the energy. Shades
                on. Volume Up.
              </span>
              ”
            </p>
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              display: "flex",
              flexFlow: "column",
              width: "100%",
              gap: "100px",
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}>
              <StaticImage
              width={321}
              height={217}
                src={"../../images/rayban-img-8.png"}
                alt={"project-img-8"}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "520px",
              }}>
              <StaticImage
              width={335}
              height={199}
                src={"../../images/rayban-img-9.png"}
                alt={"project-img-9"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}>
              <StaticImage
              width={375}
              height={179}
                src={"../../images/rayban-img-10.png"}
                alt={"project-img-10"}
              />
            </div>
          </div>
        </div>
      </div>
    </Project>
  );
}

export default ProjectRayBan;
