import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Project from "./Project";
import HomeVideoModal from "../home/HomeVideoModal";

// Misfit Wrapping - block 6
//import bannerMisfit from '../../images/home-misfit-wrapping.jpeg'
import bannerRayban from "../../images/mclaren-uncovered-hero.png";
//import img6 from '../../images/home-misfit-wrapping.png'
import Video1 from "../../media/rayban-vid-hero-720.mp4";

import Porsche from "../../images/pu-img-banner-up.png";
import fromwhere from "../../images/home-from-where-imini.jpeg";

function ProjectMcLarenUncovered(props) {
  const { projectID, rect, expanded, videoOpen } = props;
  const block = {
    id: "mclaren-uncovered",
    /* banner: (
      <StaticImage
        src={"../../images/rayban-hero.png"}
        style={{
          top: 0,
          left: 0,
          width: "100%",
          maxWidth: "unset",
          height: "100%",
        }}
        loading={"eager"}
        alt={"rayban-banner"}
        className={"project-image project-banner"}
      />
    ), */
    banner: bannerRayban,
    img: bannerRayban,
    bannerSmall: bannerRayban,
    infoheader: "Project Info",
    subheader: "Our shift starts when yours can't.",
    metadata:
      "Discover McLaren’s 60th anniversary and the U.S. debut of the 750S during the 2023 Miami Grand Prix F1 weekend. Hosted at Wynwood Walls, the event showcased McLaren vehicles as luxury sculptures amid vibrant street art. Guests explored McLaren’s history with cars representing past, present, and future, blending F1 heritage with contemporary luxury for dealers and clients.",
    info: [
      "When McLaren wanted to celebrate its 60th anniversary, its F1 racing roots and the U.S. premiere of the 750S, Public School knew exactly what to do — throw an epic party. To captivate dealer and clientele guests, the team designed an unexpected, exclusive experience in a truly unique environment during the 2023 Grand Prix F1 race weekend in Miami.",
      "The invite-only party was held at Wynwood Walls — Miami’s original street art museum. The artful urban environment provided the perfect backdrop for the vehicle showcase, making the McLaren vehicles feel like luxury sculptures in a gritty outdoor gallery. Public School designed the guest experience to feel like a journey through time. The past was represented by a race, the present by a road and the future by the 750S. Each vehicle had its own vignette to highlight a different moment in McLaren’s rich history. ",
    ],
    brand: "McLaren",
    project: "Uncovered",
    tag: "Branding",
    prevTag: "Porsche Unseen SXSW",
    nextTag: "MINI Countryman Launch",
    prevLink: "/project/porsche-unseen",
    prevImage: Porsche,
    nextLink: "/project/from-where-i-mini",
    nextImage: fromwhere,
  };
  const marqueeText = [
    { type: "p", text: "McLaren", id: "mclaren-uncovered" },
    { type: "span", text: "Uncovered" },
    { type: "p", text: "McLaren" },
    { type: "span", text: "Uncovered" },
    { type: "p", text: "McLaren", id: "mclaren-uncovered" },
    { type: "span", text: "Uncovered" },
  ];

  return (
    <Project block={block} title={marqueeText} {...props}>
      <div className="project-video-wrap">
        <div className={"project-video"}>
            <HomeVideoModal
            key={`homeBlock-${block.id}`}
            block={block}
            video="https://stream.mux.com/U6cGLeWpANAuqjUUAnWZZYCU22CtJw01XbKvDl9WiWf8.m3u8"
            prevRect={rect}
            navExpand={videoOpen}
            hideSound={true}
            />
        </div>
      </div>
      <div className="pwrap">
        {/* <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-12"
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}>
            <StaticImage
              src={"../../images/rayban-video.png"}
              alt={"project-video"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div> */}
        {/* <div className="pgrid">
          <div
            className="pgrid-cols-14"
            style={{
              width: "100%",
              aspectRatio: "16/9",
              display: "flex",
            }}>
            <HomeVideoModal
              key={`homeBlock-${block.id}`}
              block={block}
              prevRect={rect}
              navExpand={videoOpen}
              video={Video1}
            />
          </div>
        </div> */}

        <div
          className="pgrid-12"
          style={{
            marginTop: "180px",
            maxWidth: "1200px",
          }}>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-1.png"}
              alt={"project-img-1"}
            />
          </div>

          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
              //maxWidth: "590px",
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
            }}>
            <p
              className="p-matter"
              style={{
                width: "100%",
                padding: "0 102px 146px 102px",
              }}>
              We also understood that standing out from the crowd in Miami
              requires surprises that delight guests at every turn. To achieve
              this, we featured live models painted in McLaren camo and an
              incredible Artura art car by Nick Thomm—an artist who aligns
              exquisitely with the brand’s ethos. Another unexpected discovery
              moment for the guests involved the enclosed gallery installation
              that set the stage for the 750S reveal.
            </p>
            <div
              className="pgrid-cols-6"
              style={{
                width: "100%",
              }}>
              <StaticImage
                src={"../../images/mclaren-uncovered-2.png"}
                alt={"project-img-2"}
              />
            </div>
          </div>
        </div>

        <div
          className="pgrid-12"
          style={{
            maxWidth: "1200px",
          }}>
          <div className="pgrid-cols-6">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <StaticImage
                src={"../../images/mclaren-uncovered-3.png"}
                alt={"project-img-3"}
                style={{
                  maxWidth: "374px",
                }}
              />
            </div>
          </div>
          <div className="pgrid-cols-6">
            <div
              style={{
                width: "100%",
              }}>
              <StaticImage
                src={"../../images/mclaren-uncovered-4.png"}
                alt={"project-img-4"}
                style={{
                  maxWidth: "231px",
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "180px",
            width: "100%",
          }}>
          <StaticImage
            src={"../../images/mclaren-uncovered-5.jpg"}
            alt={"project-img-5"}
            style={{
              width: "100%",
              height: "720px",
            }}
          />
        </div>

        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-12"
            style={{
              marginTop: "180px",
              display: "flex",
              justifyContent: "center",
            }}>
            <p className="project-tagline">
              “Guests <span>absolutely loved the fresh approach</span> to
              celebrating the history of the brand.”
            </p>
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div
          className="pgrid-12"
          style={{
            marginTop: "180px",
            maxWidth: "1200px",
          }}>
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-4"
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
              justifyContent: "flex-end",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-6.png"}
              alt={"project-img-6"}
            />
          </div>

          <div
            className="pgrid-cols-7"
            style={{
              width: "100%",
              //maxWidth: "590px",
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
            }}>
            <div
              style={{
                width: "100%",
              }}>
              <StaticImage
                src={"../../images/mclaren-uncovered-7.png"}
                alt={"project-img-7"}
              />
            </div>
          </div>
        </div>

        <div
          className="pgrid"
          style={{
            maxWidth: "1440px",
          }}>
          <div className="pgrid-cols-8">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <StaticImage
                src={"../../images/mclaren-uncovered-8.png"}
                alt={"project-img-8"}
                style={{
                  maxWidth: "811px",
                }}
              />
            </div>
          </div>
          <div className="pgrid-cols-5">
            <div
              style={{
                width: "100%",
              }}>
              <StaticImage
                src={"../../images/mclaren-uncovered-9.png"}
                alt={"project-img-9"}
                style={{
                  maxWidth: "483px",
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="pgrid-12"
          style={{
            marginTop: "180px",
            maxWidth: "1200px",
          }}>
          <div className="pgrid-cols-12">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <StaticImage
                src={"../../images/mclaren-uncovered-10.png"}
                alt={"project-img-10"}
                style={{
                  maxWidth: "1200px",
                }}
              />
            </div>
          </div>
        </div>

        
      </div>

      <div className="pwrap-mobile">
        {/* <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
            }}>
            <HomeVideoModal
              key={`homeBlock-${block.id}`}
              block={block}
              prevRect={rect}
              navExpand={videoOpen}
              video={Video1}
            />
          </div>
        </div> */}
        {/* <div className="pgrid">
          <div
            style={{
              width: "100%",
            }}>
            <div
              className={"project-video"}
              style={{
                width: "100%",
              }}>
              <HomeVideoModal
                key={`homeBlock-${block.id}`}
                block={block}
                video="https://stream.mux.com/MuNdPK62Cy3QRejX9Qa5hkIGH5k6WB4a00kLfpfnVvyg.m3u8"
                prevRect={rect}
                navExpand={videoOpen}
                hideSound={true}
              />
            </div>
          </div>
        </div> */}

        {/* <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}>
            <StaticImage
              src={"../../images/rayban-video.png"}
              alt={"project-video"}
            />
          </div>
        </div> */}

        <div className="pgrid"
            style={{
                marginTop: "100px",
            }}
        >
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
              display: "flex",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-1.png"}
              alt={"project-img-1"}
            />
          </div>
        </div>

        <div
          className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <p className="p-matter">
              We also understood that standing out from the crowd in Miami
              requires surprises that delight guests at every turn. To achieve
              this, we featured live models painted in McLaren camo and an
              incredible Artura art car by Nick Thomm—an artist who aligns
              exquisitely with the brand’s ethos. Another unexpected discovery
              moment for the guests involved the enclosed gallery installation
              that set the stage for the 750S reveal.
            </p>
          </div>
          <div className="pgrid-cols-1"></div>
        </div>


        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-2.png"}
              alt={"project-img-2"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
        <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-3.png"}
              alt={"project-img-3"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
        <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-4.png"}
              alt={"project-img-4"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>


        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-5.png"}
              alt={"project-img-5"}
            />
          </div>
        </div>


        <div className="pgrid">
          <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <p className="project-tagline">
              “Guests{" "}<span>absolutely loved the fresh approach</span>{" "}to
              celebrating the history of the brand.”
            </p>
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-7.png"}
              alt={"project-img-7"}
            />
          </div>
        </div>

        <div className="pgrid">
        <div className="pgrid-cols-1"></div>
          <div
            className="pgrid-cols-6"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-6.png"}
              alt={"project-img-6"}
            />
          </div>
          <div className="pgrid-cols-1"></div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-8.png"}
              alt={"project-img-8"}
            />
          </div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-9.png"}
              alt={"project-img-9"}
            />
          </div>
        </div>

        <div className="pgrid">
          <div
            className="pgrid-cols-8"
            style={{
              width: "100%",
            }}>
            <StaticImage
              src={"../../images/mclaren-uncovered-10.png"}
              alt={"project-img-10"}
            />
          </div>
        </div>

      </div>
    </Project>
  );
}

export default ProjectMcLarenUncovered;
